import * as React from "react";
import { App } from "./App";
import "./index.css";
import "./fonts/IQOSSans-Regular.otf";
import "./fonts/IQOSSans-Light.otf";
import "./fonts/IQOSSans-Bold.otf";
import { createRoot } from "react-dom/client";

const container = document.getElementById("root") as HTMLElement;
if (!container) throw new Error("Failed to find the root element");
const root = createRoot(container);

// const rootElement = document.getElementById("root") as HTMLElement;
// if (rootElement.hasChildNodes()) {
//   hydrate(<App />, rootElement);
// } else {
//   render(<App />, rootElement);
// }

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
