/** @jsxImportSource @emotion/react */

import { PropsWithChildren } from "react";
import { motion, MotionStyle } from "framer-motion";

export const MotionDiv = ({
  children,
  key,
  style,
}: PropsWithChildren<{
  key: number | string;
  style?: MotionStyle | undefined;
}>) => {
  return (
    <motion.div
      style={{
        position: "absolute",
        ...style,
      }}
      key={key}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2, ease: "easeInOut" }}
    >
      {children}
    </motion.div>
  );
};
