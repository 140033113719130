/** @jsxImportSource @emotion/react */

import { Trans } from "react-i18next";
import { ReactComponent as IqosLogo } from "../assets/icons/iqos-logo.svg";
import { useEffect } from "react";
import { ArrowBack, ArrowNext } from "../assets";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  currentChapterNumberAtom,
  currentQuestionNumberAtom,
  hasAnsweredAllAtom,
  hasQuizStartedAtom,
} from "../utils/state";
import { useQuestions } from "../utils/useQuestions";
import { AnimatePresence, motion } from "framer-motion";
import { MotionDiv } from "./MotionDiv";
import { Typography } from "./Typograhy";
import tw from "twin.macro";
import { Button } from "./Button";

export const Footer = () => {
  const hasQuizStarted = useRecoilValue(hasQuizStartedAtom);
  const [currentChapter, setCurrentChapter] = useRecoilState(
    currentChapterNumberAtom,
  );
  const [questionNumber, setQuestionNumber] = useRecoilState(
    currentQuestionNumberAtom,
  );

  const setHasAnsweredAllAtom = useSetRecoilState(hasAnsweredAllAtom);

  const { currentQuestion } = useQuestions();

  useEffect(() => {
    if (currentQuestion.chapter !== currentChapter && hasQuizStarted) {
      setCurrentChapter(currentQuestion.chapter);
    }
  }, [currentQuestion, currentChapter, hasQuizStarted, setCurrentChapter]);

  return (
    <AnimatePresence exitBeforeEnter>
      {hasQuizStarted ? (
        <MotionDiv
          key={"header-during-quiz"}
          style={{ width: "100%", bottom: 0 }}
        >
          <div className={"flex flex-col w-full justify-center items-center"}>
            <div className={"flex flex-row"}>
              <Button.Outlined
                containerCss={[tw`py-0 px-3 min-w-10 xl:(py-0 px-3 min-w-10)`]}
                lead={() => <ArrowBack />}
                onClick={() => {
                  setQuestionNumber((prev) => Math.max(prev - 1, 0));
                }}
              />
              <Button.Outlined
                lead={() => <ArrowNext />}
                containerCss={[tw`py-0 px-3 min-w-10 xl:(py-0 px-3 min-w-10)`]}
                onClick={() => {
                  setQuestionNumber((prev) => {
                    return Math.min(prev + 1, 8);
                  });
                  if (questionNumber === 8) {
                    setHasAnsweredAllAtom(true);
                  }
                }}
              />
            </div>
            <div className={"flex-row flex"}>
              {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((qNumber, index) => {
                const isSelected = qNumber === questionNumber;
                return (
                  <motion.div
                    className={
                      "flex border-1 w-8 h-8 justify-center items-center my-5 cursor-pointer rounded-full border-transparent"
                    }
                    css={[isSelected && tw`border-secondary`]}
                    key={qNumber}
                    onClick={() => {
                      setQuestionNumber(qNumber);
                    }}
                  >
                    <Typography.BodyMedium>{qNumber + 1}</Typography.BodyMedium>
                  </motion.div>
                );
              })}
            </div>
          </div>
        </MotionDiv>
      ) : (
        <MotionDiv
          key={"header-before-quiz"}
          style={{ width: "100%", bottom: 0 }}
        >
          <div
            className={"flex w-full justify-between px-16 py-4 items-center"}
          >
            <Typography.BodySmall>
              <Trans i18nKey={"footer.source"} />
            </Typography.BodySmall>
            <IqosLogo />
          </div>
        </MotionDiv>
      )}
    </AnimatePresence>
  );
};
