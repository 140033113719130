/** @jsxImportSource @emotion/react */

import { Trans, useTranslation } from "react-i18next";
import { BackToStart } from "./BackToStart";
import { useQuestions } from "../utils/useQuestions";
import { useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";
import {
  currentQuestionNumberAtom,
  hasQuizStartedAtom,
  selectedAnswersIdAtom,
} from "../utils/state";
import { AnimatePresence } from "framer-motion";
import { MotionDiv } from "./MotionDiv";
import { Typography } from "./Typograhy";
import tw from "twin.macro";
import { Button } from "./Button";
import DropdownMenu from "./Menu";

export const Header = () => {
  const { t } = useTranslation();
  const { currentQuestion } = useQuestions();

  const [hasQuizStarted, setHasQuizStarted] =
    useRecoilState(hasQuizStartedAtom);
  const resetAnswers = useResetRecoilState(selectedAnswersIdAtom);
  const setCurrentQuestionNumber = useSetRecoilState(currentQuestionNumberAtom);

  return (
    <div className={"bg-primary w-full min-h-[260px]"}>
      <AnimatePresence exitBeforeEnter>
        {hasQuizStarted ? (
          <MotionDiv key={"header-during-quiz"} style={{ width: "100%" }}>
            <div className={"px-9 py-8 flex flex-col"}>
              <div className={"flex flex-row self-start"}>
                <BackToStart
                  onBack={() => {
                    resetAnswers();
                    setHasQuizStarted(false);
                  }}
                />
              </div>
              <div
                className={
                  "flex flex-col items-center justify-center space-y-5"
                }
              >
                <Typography.QuestionSubheader>
                  <Trans i18nKey={currentQuestion?.title} />
                </Typography.QuestionSubheader>
                <Typography.QuestionHeader
                  containerCss={[tw`font-bold text-center`]}
                >
                  <Trans i18nKey={currentQuestion?.header} />
                </Typography.QuestionHeader>
              </div>
            </div>
          </MotionDiv>
        ) : (
          <MotionDiv key={"header-before-quiz"} style={{ width: "100%" }}>
            <div className={"flex-col flex px-9 pt-3 space-y-5"}>
              <div className={"flex flex-row self-end"}>
                <DropdownMenu />
              </div>
              <div className={"flex flex-col items-center space-y-5"}>
                <Typography.InitialHeader
                  containerCss={[tw`font-bold text-center mb-4`]}
                >
                  <Trans i18nKey={"header.initial"} />
                </Typography.InitialHeader>
                <Button.Contained
                  variant={"dark"}
                  textCss={[tw`text-[24px]`]}
                  containerCss={[tw`max-w-[280px] max-h-[60px]`]}
                  onClick={() => {
                    setCurrentQuestionNumber(0);
                    setHasQuizStarted(true);
                  }}
                >
                  {t("button.startQuiz")}
                </Button.Contained>
              </div>
            </div>
          </MotionDiv>
        )}
      </AnimatePresence>
    </div>
  );
};
