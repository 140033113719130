import { useRecoilValue } from "recoil";
import { currentQuestionNumberAtom } from "./state";

export interface IQuestion {
  id: number;
  title: string;
  chapter: number;
  header: string;
  icon: string;
  answers: IAnswer[];
  true: string;
  false: string;
}

export interface IAnswer {
  correct: number;
  id: string;
  questionId: number;
  title: string;
  position: "left" | "mid" | "right";
}

const iconsByQuestion = [
  "leaves.svg",
  "smoke.svg",
  "leaves.svg",
  "tar.svg",
  "co-cloud.svg",
  "no-fire.svg",
  "95-percent-reduce.svg",
  "iqos-cartridge.svg",
  "spyglass.svg",
];

export const useQuestions = () => {
  const currentQuestionNumber = useRecoilValue(currentQuestionNumberAtom);
  const questions = [
    [1, 0],
    [0, 1],
    [1, 0],
    [0, 1],
    [1, 0],
    [1, 0],
    [0, 0, 1],
    [1, 0, 0],
    [0, 1, 0],
  ].map((numOfAnswers, index) => ({
    id: index,
    chapter: [0, 1, 2].includes(index)
      ? 0
      : [3, 4, 5, 6].includes(index)
      ? 1
      : 2,
    title: `question.${index}.title`,
    icon: iconsByQuestion[index],
    header: `question.${index}.header`,
    true: `q-${index + 1}-true.png`,
    false: `q-${index + 1}-false.png`,
    answers: numOfAnswers.map((correct, index2, array) => ({
      id: `question-${index}-answer-${index2}`,
      questionId: index,
      position:
        index2 === 0
          ? "left"
          : array.length === 2 && index2 === 1
          ? "right"
          : array.length === 3 && index2 === 1
          ? "mid"
          : "right",
      title: `question.${index}.answers.${index2}`,
      correct,
    })),
  })) as IQuestion[];

  return {
    questions,
    currentQuestion: questions[currentQuestionNumber],
  };
};
