/** @jsxImportSource @emotion/react */

import { useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";
import {
  currentChapterNumberAtom,
  currentQuestionNumberAtom,
  hasAnsweredAllAtom,
  hasQuizStartedAtom,
  selectedAnswersIdAtom,
} from "../utils/state";
import { AnimatePresence } from "framer-motion";
import { Header } from "./Header";
import { ChapterTabs } from "./ChapterTabs";
import { Footer } from "./Footer";
import * as React from "react";
import { MotionDiv } from "./MotionDiv";
import { Trans, useTranslation } from "react-i18next";
import { DefaultArrowBack, IqosLogo } from "../assets";
import { Typography } from "./Typograhy";
import { Button } from "./Button";
import tw from "twin.macro";

export const Quiz = () => {
  const [hasAnsweredAll, setHasAnsweredAll] =
    useRecoilState(hasAnsweredAllAtom);
  const resetQuizAnswers = useResetRecoilState(selectedAnswersIdAtom);
  const setHasQuizStarted = useSetRecoilState(hasQuizStartedAtom);
  const setCurrentQuestionNumber = useSetRecoilState(currentQuestionNumberAtom);
  const setCurrentChapterNumber = useSetRecoilState(currentChapterNumberAtom);

  const { t } = useTranslation();
  return (
    <AnimatePresence exitBeforeEnter>
      {hasAnsweredAll ? (
        <MotionDiv
          key={"finish"}
          style={{ position: "relative", height: "100%" }}
        >
          <div
            className={
              "flex flex-col bg-primary h-full justify-around items-center"
            }
          >
            <div />
            <div />
            <div className={"flex flex-col items-center space-y-5"}>
              <Typography.FinishHeader>
                <Trans i18nKey={"finish.header"} />
              </Typography.FinishHeader>
              <Typography.QuestionHeader>
                <Trans i18nKey={"finish.subheader"} />
              </Typography.QuestionHeader>
            </div>
            <Button.Text
              lead={() => <DefaultArrowBack />}
              onClick={() => {
                resetQuizAnswers();
                setCurrentChapterNumber(0);
                setCurrentQuestionNumber(0);
                setHasAnsweredAll(false);
                setHasQuizStarted(false);
              }}
            >
              <Typography.BodyMedium containerCss={[tw`ml-3`]}>
                {t("button.takeQuizAgain")}
              </Typography.BodyMedium>
            </Button.Text>
            <IqosLogo />
          </div>
        </MotionDiv>
      ) : (
        <MotionDiv
          key={"quiz"}
          style={{ position: "relative", height: "100%" }}
        >
          <div className={"flex flex-col h-full justify-between"}>
            <Header />
            <ChapterTabs />
            <Footer />
          </div>
        </MotionDiv>
      )}
    </AnimatePresence>
  );
};
