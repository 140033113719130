import * as React from "react";
import { useEffect } from "react";
import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import resources from "./translations";
import { RecoilRoot } from "recoil";
import { Quiz } from "./components/Quiz";
import ReactGA from "react-ga4";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("lng"), // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

ReactGA.initialize("G-RTFMTFN075");

// FULL HEIGHT = 1024
// FOOTER = 145
// HEADER = 260
export const App = () => {
  useEffect(() => {
    if (!localStorage.getItem("lng")) {
      localStorage.setItem("lng", "en");
    }
  }, []);

  return (
    <RecoilRoot>
      <div className={"h-screen"}>
        <Quiz />
      </div>
    </RecoilRoot>
  );
};
