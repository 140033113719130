/** @jsxImportSource @emotion/react */
import { DefaultArrowNext } from "../assets";
import { IQuestion } from "../utils/useQuestions";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  currentChapterNumberAtom,
  currentQuestionNumberAtom,
  hasQuizStartedAtom,
} from "../utils/state";
import { Trans } from "react-i18next";
import { Typography } from "./Typograhy";
import tw from "twin.macro";

export const QuestionContainer = ({ question }: { question: IQuestion }) => {
  const [currentChapter, setCurrentChapter] = useRecoilState(
    currentChapterNumberAtom,
  );
  const setCurrentQuestionNumber = useSetRecoilState(currentQuestionNumberAtom);
  const [, setHasQuizStarted] = useRecoilState(hasQuizStartedAtom);
  const icon = require(`../assets/question-icons/${question.icon}`);

  return (
    <div
      className={
        "flex flex-col border-2 justify-center transition-all items-center rounded-[20px] py-5 space-y-5 w-[222px] cursor-pointer border-gray hover:(border-primary)"
      }
      key={question.id}
      onClick={() => {
        setCurrentQuestionNumber(question.id);
        setHasQuizStarted(true);
        if (currentChapter !== question.chapter) {
          setCurrentChapter(question.chapter);
        }
      }}
    >
      <img src={icon} alt={"19c0"} className={"max-w-[94px] max-h-[94px]"} />
      <Typography.QuestionTitle containerCss={[tw`h-[60px] text-center`]}>
        <Trans i18nKey={question.title} />
      </Typography.QuestionTitle>
      <DefaultArrowNext />
    </div>
  );
};
