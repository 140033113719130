/** @jsxImportSource @emotion/react */

import { IAnswer } from "../utils/useQuestions";
import { motion } from "framer-motion";
import { useRecoilState } from "recoil";
import { selectedAnswersIdAtom } from "../utils/state";
import { CorrectIcon, WrongIcon } from "../assets";
import { Trans, useTranslation } from "react-i18next";
import i18next from "i18next";
import { Typography } from "./Typograhy";
import tw from "twin.macro";
import ReactGA from "react-ga4";

export const AnswerContainer = ({
  answer,
  falseExplanation,
  trueExplanation,
}: {
  answer: IAnswer;
  falseExplanation: string;
  trueExplanation: string;
}) => {
  const [selectedAnswersId, setSelectedAnswersId] = useRecoilState(
    selectedAnswersIdAtom,
  );
  const { t } = useTranslation();
  // @ts-ignore
  const hasSelectedAnswer = !!selectedAnswersId[answer.questionId];
  const language = i18next.language;
  const trueAnswerExplanation = require(`../assets/answers/${language}/${trueExplanation}`);
  const falseAnswerExplanation = require(`../assets/answers/${language}/${falseExplanation}`);

  // @ts-ignore
  const isSelectedAnswer = selectedAnswersId[answer.questionId] === answer.id;
  const Icon = answer.correct ? CorrectIcon : WrongIcon;
  return (
    <motion.div
      id={answer.id}
      animate={
        hasSelectedAnswer
          ? isSelectedAnswer
            ? { opacity: 1 }
            : { opacity: 0 }
          : { opacity: 1 }
      }
      initial={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div
        className={
          "flex items-center border-3 rounded-[10px] space-x-5 space-y-5 transition-all duration-500 justify-center w-[280px] py-4 px-7 h-[220px] relative border-gray"
        }
        key={answer.id}
        css={
          isSelectedAnswer
            ? [tw`bg-secondary cursor-default hover:(border-transparent)`]
            : [tw`bg-white  cursor-pointer hover:(border-primary)`]
        }
        onClick={() => {
          if (!hasSelectedAnswer) {
            setSelectedAnswersId({
              ...selectedAnswersId,
              [answer.questionId]: answer.id,
            });
            ReactGA.event({
              action: `Question ${answer.questionId + 1}`,
              category: answer.correct ? "True" : "False",
              value: answer.correct,
              label: t(answer.title),
            });
          }
        }}
      >
        <Typography.QuestionTitle
          containerCss={[
            tw`transition-all duration-500 text-center font-bold`,
            isSelectedAnswer && tw`text-white`,
          ]}
        >
          <Trans i18nKey={answer.title} />
        </Typography.QuestionTitle>
        {answer.correct === 1 && isSelectedAnswer && (
          <img
            src={trueAnswerExplanation}
            alt={"1991"}
            className={"absolute max-w-[430px] top-0"}
            css={
              ["left", "mid"].includes(answer.position)
                ? [tw`left-[300px]`]
                : [tw`-left-[450px]`]
            }
          />
        )}
        {answer.correct === 0 && isSelectedAnswer && (
          <img
            src={falseAnswerExplanation}
            alt={"19291"}
            className={"max-w-[430px] absolute top-0"}
            css={
              ["left", "mid"].includes(answer.position)
                ? [tw`left-[300px]`]
                : [tw`-left-[450px]`]
            }
          />
        )}
        {isSelectedAnswer ? (
          <Icon style={{ position: "absolute", top: -10, right: 10 }} />
        ) : null}
      </div>
    </motion.div>
  );
};
