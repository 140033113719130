import { atom } from "recoil";

export const currentQuestionNumberAtom = atom({
  key: "currentQuestionStateAtom", // unique ID (with respect to other atoms/selectors)
  default: 0, // default value (aka initial value)
});

export const currentChapterNumberAtom = atom<null | number>({
  key: "currentChapterNumberAtom", // unique ID (with respect to other atoms/selectors)
  default: 0, // default value (aka initial value)
});

export const hasQuizStartedAtom = atom({
  key: "hasQuizStartedAtom", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

export const selectedAnswersIdAtom = atom({
  key: "selectedAnswersIdAtom",
  default: {
    0: null,
    1: null,
    2: null,
    3: null,
    4: null,
    5: null,
    6: null,
    7: null,
    8: null,
    9: null,
  },
});

export const hasAnsweredAllAtom = atom({
  key: "hasAnsweredAllAtom",
  default: false,
});
