import { useQuestions } from "../utils/useQuestions";
import { useRecoilState } from "recoil";
import { hasQuizStartedAtom } from "../utils/state";
import { AnimatePresence } from "framer-motion";
import { AnswerContainer } from "./AnswerContainer";
import { QuestionContainer } from "./QuestionContainer";
import { MotionDiv } from "./MotionDiv";

export const ChapterTab = ({ chapterNumber }: { chapterNumber: number }) => {
  const [hasQuizStarted] = useRecoilState(hasQuizStartedAtom);
  const { questions, currentQuestion } = useQuestions();
  const chapterQuestions = questions.filter((q) => q.chapter === chapterNumber);

  return (
    // <TabPanel>
    <AnimatePresence exitBeforeEnter>
      {hasQuizStarted ? (
        <MotionDiv key={`answers-${chapterNumber}`} style={{ width: "100%" }}>
          <div className={"flex flex-row space-x-4 w-full justify-center ab"}>
            {currentQuestion.answers.map((ans) => (
              <AnswerContainer
                key={ans.id}
                answer={ans}
                trueExplanation={currentQuestion.true}
                falseExplanation={currentQuestion.false}
              />
            ))}
          </div>
        </MotionDiv>
      ) : (
        <MotionDiv
          key={`chapter-${chapterNumber}-questions`}
          style={{ width: "100%" }}
        >
          <div className={"flex flex-row space-x-4 w-full justify-center"}>
            {chapterQuestions.map((question) => (
              <QuestionContainer key={question.id} question={question} />
            ))}
          </div>
        </MotionDiv>
      )}
    </AnimatePresence>
    // </TabPanel>
  );
};
