/** @jsxImportSource @emotion/react */

import { useTranslation } from "react-i18next";
import { DefaultArrowBack } from "../assets";
import { Typography } from "./Typograhy";
import { Button } from "./Button";
import tw from "twin.macro";

export const BackToStart = ({ onBack }: { onBack: () => void }) => {
  const { t } = useTranslation();
  return (
    <Button.Text
      lead={() => <DefaultArrowBack />}
      onClick={onBack}
      containerCss={[tw`py-0 px-3 min-w-10 xl:(py-0 px-3 min-w-10)`]}
    >
      <Typography.BodyMedium containerCss={[tw`ml-2`]}>
        {t("button.backToStart")}
      </Typography.BodyMedium>
    </Button.Text>
  );
};
