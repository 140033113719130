/** @jsxImportSource @emotion/react */

import { ChapterTab } from "./ChapterTab";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentChapterNumberAtom, hasQuizStartedAtom } from "../utils/state";
import * as React from "react";
import { useEffect } from "react";
import { Trans } from "react-i18next";

export const ChapterTabs = () => {
  const [currentChapter, setCurrentChapter] = useRecoilState(
    currentChapterNumberAtom,
  );
  const hasStartedQuiz = useRecoilValue(hasQuizStartedAtom);

  useEffect(() => {
    const prevTabIndex = currentChapter;
    setCurrentChapter(null);
    setTimeout(() => {
      setCurrentChapter(prevTabIndex);
    }, 10);
  }, [setCurrentChapter]);

  return (
    <div className={"flex flex-row w-full flex-1 items-start"}>
      <div className="w-full mx-auto">
        {/* Tabs */}
        <div className="flex items-center justify-center mb-8 h-[80px] border-b-gray border-b-2">
          <button
            disabled={hasStartedQuiz}
            className={`flex justify-center flex-1 h-[80px] transition-all duration-500 px-4 text-center items-center max-w-[325px] ${
              currentChapter === 0
                ? "border-b-secondary border-b-3"
                : "border-b-gray border-b-1"
            }`}
            onClick={() => setCurrentChapter(0)}
          >
            <Trans i18nKey={"chapters.0"} />
          </button>
          <button
            disabled={hasStartedQuiz}
            className={`flex justify-center flex-1 h-[80px] transition-all duration-500 px-4 text-center  items-center max-w-[325px] ${
              currentChapter === 1
                ? "border-b-secondary border-b-3"
                : "border-b-gray border-b-1"
            }`}
            onClick={() => setCurrentChapter(1)}
          >
            <Trans i18nKey={"chapters.1"} />
          </button>
          <button
            disabled={hasStartedQuiz}
            className={`flex justify-center flex-1 h-[80px] transition-all duration-500 px-4 text-center  items-center max-w-[325px] ${
              currentChapter === 2
                ? "border-b-secondary border-b-3"
                : "border-b-gray border-b-1"
            }`}
            onClick={() => setCurrentChapter(2)}
          >
            <Trans i18nKey={"chapters.2"} />
          </button>
        </div>

        {/* Tab Content */}
        <div id="tabContent">
          <div
            className={`tab-content ${currentChapter === 0 ? "" : "hidden"}`}
          >
            <ChapterTab chapterNumber={0} />
          </div>
          <div
            className={`tab-content ${currentChapter === 1 ? "" : "hidden"}`}
          >
            <ChapterTab chapterNumber={1} />
          </div>
          <div
            className={`tab-content ${currentChapter === 2 ? "" : "hidden"}`}
          >
            <ChapterTab chapterNumber={2} />
          </div>
        </div>
      </div>
    </div>
  );
};
