import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { ChevronRight } from "../assets";

const DropdownMenu = () => {
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(i18n.language);

  const restOfTheLanguages = ["en", "de", "fr", "it"].filter(
    (lng) => lng !== selectedItem,
  );
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleItemClick = async (item) => {
    setSelectedItem(item);
    setIsDropdownOpen(false);
    await i18n.changeLanguage(item);
    localStorage.setItem("lng", item);
  };

  return (
    <div className="relative inline-block text-left">
      <div className={"flex flex-row items-center"}>
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 text-sm text-secondary font-medium bg-transparent"
          onClick={toggleDropdown}
        >
          {t(`language.${selectedItem}`)}
        </button>
        <ChevronRight />
      </div>

      {isDropdownOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md bg-transparent">
          <div className="py-1">
            {restOfTheLanguages.map((item) => (
              <button
                key={item}
                onClick={() => handleItemClick(item)}
                className="block px-4 py-2 text-sm text-secondary w-full text-right"
              >
                {t(`language.${item}`)}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
